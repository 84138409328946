import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    background: theme.colors.background,
    minHeight: '24rem',
    left: 0,
    width: '100%',
    '&.sub-menu-enter': {
      opacity: 0,
    },
    '&.sub-menu-enter-active': {
      opacity: 1,
      transition: 'opacity 150ms',
    },
    '&.sub-menu-exit': {
      opacity: 1,
    },
    '&.sub-menu-exit-active': {
      opacity: 0,
      transition: 'opacity 150ms',
    },
  },
  container: {
    width: '100%',
    margin: [[0, 'auto']],
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '2rem 0',
  },
  boxCategory: {
    flex: '0 1 100%',
    padding: '0 1rem',
    '& h4': {
      display: 'block',
      padding: '1.5rem 0',
      textTransform: 'uppercase',
      fontSize: '1.4rem',
      fontWeight: 300,
      width: '100%',
      borderBottom: `1px solid ${theme.colors.primary}`,
    },
    '& a': {
      textDecoration: 'none',
      color: theme.colors.primary,
      fontSize: '1.2rem',
      fontWeight: 400,
    },
    '& ul': {
      margin: 0,
    },
    '& li': {
      listStyle: 'none',
      lineHeight: 1.2,
      color: theme.colors.text,
    },
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      flex: '0 1 50%',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      flex: '0 1 25%',
    },
  },
}))

export default useStyles
