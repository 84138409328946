import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Layout, Loading, SEO, Link,
} from '../../ui'
import useStyles from './products-all-style'

function ProductsAllView({
  // home page content group code
  // the page will be ready after loading content group data is end
  items,
  pageReady = true,
  seoTitle,
}) {
  const { t } = useTranslation()

  const styles = useStyles()
  const itemWithChldren = _.filter(items, (item) => !_.isEmpty(item.children))
  const itemWithoutChldren = _.filter(items, (item) => _.isEmpty(item.children))
  return (
    <Layout>
      {/* passing page specific data for SEO */}
      <SEO title={seoTitle} />
      {
        pageReady
          ? (
            <div
              className={styles.containerFluid}
            >
              <div
                className={styles.container}
              >
                {
          !_.isEmpty(itemWithoutChldren) && (
            <div className={styles.boxCategory}>
              <h4>{t('ui.navbar.submenu.shopBy')}</h4>
              <ul>
                {
                  itemWithoutChldren.map((item) => (
                    <li key={item.key}>
                      <Link to={item.url}>
                        {item.text}
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        }
                {
          itemWithChldren.map((item) => (
            <div key={item.key} className={styles.boxCategory}>
              <h4>{item.text}</h4>
              <ul>
                { item.children.length > 0
                  ? item.children.map(
                    (child) => (
                      <li key={child.key}>
                        <Link to={child.url}>
                          {child.text}
                        </Link>
                      </li>
                    ),
                  )
                  : (
                    <li>
                      <Link to={item.url}>
                        {t('ui.header.menu.all')}
                      </Link>
                    </li>
                  )}
              </ul>
            </div>
          ))
        }
              </div>
            </div>
          )
          : (
            <Loading />
          )
      }
    </Layout>
  )
}

export default ProductsAllView
