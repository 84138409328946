/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useDepartments } from 'react-omnitech-api'
import { useMenu } from '../../hook'

import ProductsAllView from './products-all-view'

const ProductsAllContoller = () => {
  const { departments } = useDepartments()
  const { getDepartment } = useMenu()
  const [departmentsAndCategories, setDepartmentsAndCategories] = useState([])

  useEffect(() => {
    setDepartmentsAndCategories(_.map(departments, (department) => getDepartment(department.id)))
  }, [departments, getDepartment])

  return (
    <ProductsAllView items={departmentsAndCategories} />
  )
}

export default ProductsAllContoller
